import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContextProvider";
import { POP_UP, VIEW } from "../../util/Constants";
import log from "../../util/Logger";
import { JobContext } from "../../context/JobContextProvider";
import { useUtilManager } from "../../util/UtilManager";
import GenericButton from "../../module/GenericButton";
import { toast } from "react-toastify";

const getJobApi = 'https://m9l0gpbw58.execute-api.ap-south-1.amazonaws.com/Prod/get-job'

export default function JobDetailsView() {
    const location = useLocation();
    const currentPath = location.pathname;
    const { setPopup, setIsLoading, uid, jobDetailsViewRefresher, setJobDetailsViewRefresher } = useContext(GlobalContext);
    const { updateJob, setSelectedJobs } = useContext(JobContext);
    const [job, setJob] = useState(null);
    let { id } = useParams();
    const {
        isQA,
        capitalizeFirstChar,
        formatTs,
        handleSubmitToCandidatesClick,
        handleSubmitYourCandidatesClick,
        handleFindSimilarJobsClick,
        handleFindCandidatesClick,
        handleSubmissionToJobClick,
        handleSubmissionOfJobClick
    } = useUtilManager();

    const isActive = useMemo(() => {
        return job !== null && job.status === 'active';
    }, [job]);

    const isMyJob = useMemo(() => {
        return job !== null && job.uid === uid;
    }, [job, uid]);

    const fetchJobDetails = async (jobId) => {
        setIsLoading(true);
        const payload = {
            job_id: jobId
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getJobApi, postParams);
            // log.debug('Response received:', response);
            if (response.ok) {
                const data = await response.json();
                const desc = removeLinesBetweenFromAndReplyTo(data.desc);
                setJob({ ...data, desc });
                setSelectedJobs([data]);
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching jobs:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            toast.error("Error fetching job!");
        } finally {
            setIsLoading(false);
        }
    };

    const removeLinesBetweenFromAndReplyTo = (text) => {
        const lines = text.split("\n");
        let isInBlock = false;
        const resultLines = lines.filter((line) => {
            if (line.includes("From:")) {
                isInBlock = true;
            }
            if (isInBlock && line.includes("Reply to:")) {
                isInBlock = false;
                return false;
            }
            return !isInBlock;
        });
        return resultLines.join("\n");
    };

    const handleUpdateJob = () => {
        setPopup(POP_UP.UPDATE_JOB_POPUP);
    }

    const handleRenewDate = async () => {
        const updatedJob = {
            id: job.id
        };
        await updateJob(updatedJob);
        setJobDetailsViewRefresher((jobDetailsViewRefresher + 1) % 2);
    }

    const handleDeactivateJob = async () => {
        const updatedJob = {
            id: job.id,
            status: 'inactive',
            uid_status: job.uid + '#inactive',
        };
        await updateJob(updatedJob);
        setJobDetailsViewRefresher((jobDetailsViewRefresher + 1) % 2);
    }

    const handleActivateJob = async () => {
        const updatedJob = {
            id: job.id,
            status: 'active',
            uid_status: job.uid + '#active',
        };
        await updateJob(updatedJob);
        setJobDetailsViewRefresher((jobDetailsViewRefresher + 1) % 2);
    }

    const handleDeleteJob = async () => {
        const updatedJob = {
            id: job.id,
            status: 'deleted',
            uid_status: job.uid + '#deleted'
        };
        await updateJob(updatedJob);
        setJobDetailsViewRefresher((jobDetailsViewRefresher + 1) % 2);
    }

    useEffect(() => {
        const currentView = currentPath.split('/')[1];
        if (id === undefined || currentView !== VIEW.JOB_DETAILS) {
            return;
        }
        fetchJobDetails(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPath, jobDetailsViewRefresher]);

    return (
        <div className="font-roboto text-[12px] mt-10 mb-10">
            {job && (<div>
                <div className="mb-2 text-right font-roboto text-[10px] font-semibold flex flex-row justify-between text-[#e8ddfe]">
                    <div className="flex flex-row justify-start">
                        {isMyJob && (
                            <>
                                <GenericButton enable={true} callback={handleSubmitToCandidatesClick} text={"Submit to Candidates"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={true} callback={handleSubmissionToJobClick} text={"Submissions To this Job"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={true} callback={handleSubmissionOfJobClick} text={"My Submissions Of this Job"} mr={'mr-8'} ml={'ml-0'} />
                            </>
                        )}
                        {!isMyJob && (
                            <>
                                <GenericButton enable={true} callback={handleSubmitYourCandidatesClick} text={"Submit Your Candidates"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={true} callback={handleFindSimilarJobsClick} text={"Similar Jobs"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={true} callback={handleFindCandidatesClick} text={"Find Candidates"} mr={'mr-2'} ml={'ml-0'} />
                                {isQA && <GenericButton enable={true} callback={handleDeleteJob} text={"Delete"} mr={'mr-2'} ml={'ml-0'} />}
                            </>
                        )}
                    </div>
                    <div className="flex flex-row justify-end">
                        {isMyJob && (
                            <>
                                <GenericButton enable={true} callback={handleRenewDate} text={"Renew Date"} mr={'mr-2'} ml={'ml-0'} />
                                {isActive && <GenericButton enable={true} callback={handleDeactivateJob} text={"Deactivate"} mr={'mr-2'} ml={'ml-0'} />}
                                {!isActive && <GenericButton enable={true} callback={handleActivateJob} text={"Activate"} mr={'mr-2'} ml={'ml-0'} />}
                                {/* <GenericButton enable={true} callback={handleDeleteJob} text={"Delete"} mr={'mr-2'} ml={'ml-0'} /> */}
                                <GenericButton enable={true} callback={handleUpdateJob} text={"Update"} mr={'mr-0'} ml={'ml-0'} />
                            </>
                        )}
                    </div>
                </div>
                <table
                    className="text-left break-words overflow-hidden bg-[#eaeaea] text-gray-800"
                    cellPadding="2"
                    cellSpacing="0"
                    style={{ tableLayout: "fixed", width: "750px" }}
                >
                    <tbody>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold w-1/6"> Title: </td>
                            <td className="p-1 w-5/6"> {job.title} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Location: </td>
                            <td className="p-1"> {job.loc} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Contract Type: </td>
                            <td className="p-1"> {job.contract ? job.contract : "Not Available"} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Required Experience: </td>
                            <td className="p-1"> {job.exp ? job.exp : "Not Available"} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Applicable Visa: </td>
                            <td className="p-1"> {job.visas ? job.visas : "Not Available"} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Work Preference: </td>
                            <td className="p-1"> {job.work_pref ? job.work_pref : "Not Available"} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Posted By: </td>
                            <td className="p-1"> {job.uid} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Status: </td>
                            <td className="p-1"> {capitalizeFirstChar(job.status)} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Posted On: </td>
                            <td className="p-1"> {formatTs(job.create_ts)} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1 font-semibold"> Updated On: </td>
                            <td className="p-1"> {formatTs(job.ts)} </td>
                        </tr>
                        <tr className="border-b-4 border-white">
                            <td className="p-1" colSpan="2">
                                <span className="font-semibold">Job Description:</span>
                                <br />
                                {job.desc.split("\n").map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>)}
        </div>
    );
}
