import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { POP_UP, VIEW } from "../util/Constants";
import { GlobalContext } from "./GlobalContextProvider";
import { useNavigate } from "react-router-dom";
/* eslint-disable no-unused-vars */
import log from "../util/Logger";
import { toast } from "react-toastify";
import { useUtilManager } from "../util/UtilManager";
/* eslint-enable no-unused-vars */

export const CandidateContext = createContext();

const candidateSearchApi = 'https://bij9bzpml5.execute-api.ap-south-1.amazonaws.com/Prod/search-candidates';
const updateCandidateApi = 'https://hejha9p2m9.execute-api.ap-south-1.amazonaws.com/Prod/update-candidate';
const getCandidatesApi = 'https://0wjwdob7zc.execute-api.ap-south-1.amazonaws.com/Prod/get-candidates'
const getSubmissionsToApi = 'https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/get-submissions-to'
const getSubmissionsOfApi = 'https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/get-submissions-of'

export const CandidateContextProvider = ({ children }) => {
    const { uid, setPopup, setIsLoading, isLoggedIn, setLoadingBarDuration } = useContext(GlobalContext);
    const addCandidatePopupRef = useRef(null);
    const [selectedCandidates, setSelectedCandidates] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [lastEvaluatedKeyCandidates, setLastEvaluatedKeyCandidates] = useState(null);
    const [multipleSelectionAllowedForCandidates, setMultipleSelectionAllowedForCandidates] = useState(false);
    const [searchBoxValueForCandidate, setSearchBoxValueForCandidate] = useState("");
    const [status, setStatus] = useState('active');
    const navigate = useNavigate();

    const isAnyCandidateSelected = useMemo(() => {
        return selectedCandidates.length > 0;
    }, [selectedCandidates]);

    const sortBasedOnDateAndScore = (results) => {
        return results.sort((a, b) => {
            const dateA = new Date(a.ts * 1000);
            const dateB = new Date(b.ts * 1000);
            const yearDiff = dateB.getFullYear() - dateA.getFullYear();
            const monthDiff = dateB.getMonth() - dateA.getMonth();
            const dayDiff = dateB.getDate() - dateA.getDate();
    
            if (yearDiff !== 0) return yearDiff;
            if (monthDiff !== 0) return monthDiff;
            if (dayDiff !== 0) return dayDiff;
            const scoreA = a.score;
            const scoreB = b.score;
            if (scoreA < scoreB) return 1;
            if (scoreA > scoreB) return -1;
            return 0;
        });
    };

    const fetchCandidates = async (key = null, context = null) => {
        if (context === VIEW.MY_CANDIDATES && !isLoggedIn) {
            return;
        }
        setSelectedCandidates([]);
        setCandidateList([]);
        setIsLoading(true);
        const payload = {
            last_evaluated_key: key,
            context: context,
            uid: uid,
            status: status
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getCandidatesApi, postParams);
            if (response.ok) {
                const data = await response.json();
                if (data.Items.length !== 0) {
                    setCandidateList(data.Items);
                }
                const returnedLastEvaluatedKey = data.LastEvaluatedKey;
                if (returnedLastEvaluatedKey) {
                    const encoded = btoa(
                        unescape(encodeURIComponent(JSON.stringify(returnedLastEvaluatedKey)))
                    );
                    setLastEvaluatedKeyCandidates(encoded);
                } else {
                    setLastEvaluatedKeyCandidates(null);
                }
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching candidates:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getSubmissionsToJob = async (job) => {
        setSelectedCandidates([]);
        setCandidateList([]);
        setIsLoading(true);
        const payload = {
            dst_id: job.id,
            dst_type: 'job',
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getSubmissionsToApi, postParams);
            if (response.ok) {
                const data = await response.json();
                if (data.length !== 0) {
                    setCandidateList(data);
                }
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching candidates:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getSubmissionsOfJob = async (job) => {
        setSelectedCandidates([]);
        setCandidateList([]);
        setIsLoading(true);
        const payload = {
            src_id: job.id,
            src_type: 'job',
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getSubmissionsOfApi, postParams);
            if (response.ok) {
                const data = await response.json();
                if (data.length !== 0) {
                    setCandidateList(data);
                }
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching candidates:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOutsideAddCandidatePopupClick = (event) => {
        if (addCandidatePopupRef.current && !addCandidatePopupRef.current.contains(event.target)) {
            setPopup(POP_UP.NONE);
        }
    };

    const handleAddCandidateClick = (event) => {
        if(!isLoggedIn) {
            toast.warn("Please login to add candidate");
            navigate(`/${VIEW.LOGIN}`);
            return;
        }
        if(selectedCandidates.length !== 0) {
            toast.warn("Please deselect the candidate before adding a new one");
            return;
        }
        setPopup(POP_UP.ADD_CANDIDATE_POPUP);
    };

    const handleCandidateClick = (cand) => {
        // window.scrollTo(0, 0);
        window.open(`/${VIEW.CANDIDATE_DETAILS}/${cand.id}`, '_blank');
    };
      
    const searchCandidates = async (searchType, searchValue, scope='all', user=null, useCase='search') => {
        setIsLoading(true);
        setCandidateList([]);
        setSelectedCandidates([]);
        const payload = {
            search_type: searchType,
            search_value: searchValue,
            scope: scope,
            uid: user,
            use_case: useCase
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(candidateSearchApi, postParams);
            if (response.ok) {
                const data = await response.json();
                sortBasedOnDateAndScore(data);
                setCandidateList(data);
                window.scrollTo(0, 0);
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching candidates:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleFindSimilarCandidates = (cand, scope, user) => {
        navigate(`/${VIEW.CANDIDATE_SEARCH_RESULTS}/${cand.id}`)
        let value = cand.name;
        if (cand.loc !== 'NA') { value += ", Location: " + cand.loc; }
        if (cand.exp) { value += ", Exp: " + cand.exp + " Years"; }
        if (cand.top_skills) { value += ", Top Skills: " + cand.top_skills; }
        setSearchBoxValueForCandidate(value);
        searchCandidates('cand_id', cand.id, scope, user);
    }

    const handleFindCandidates = (job, scope, user, useCase='search') => {
        if (job === null || job === undefined) {
            navigate(-1);
            return;
        }
        setSearchBoxValueForCandidate(job.title + " at " + job.loc);
        searchCandidates('job_id', job.id, scope, user, useCase);
    }

    const handleTextSearchCandidates = (textAreaValue, scope, user) => {
        navigate(`/${VIEW.CANDIDATE_SEARCH_RESULTS}`)
        setSearchBoxValueForCandidate(textAreaValue);
        searchCandidates('text', textAreaValue, scope, user);
    };

    const isCandidateSelected = (candidate) => {
        return selectedCandidates.some((c) => c.id === candidate.id);
    }

    const handleCheckBoxChangeOnCandidateList = (candidate) => {
        if(multipleSelectionAllowedForCandidates) {
            let selected = [...selectedCandidates];
            if (selected.some((c) => c.id === candidate.id)) {
                selected = selected.filter((c) => c.id !== candidate.id);
            } else {
                if (selected.length >= 5) {
                    toast.warn("You can only select up to 5 candidates !!!");
                    return;
                }
                selected.push(candidate);
            }
            setSelectedCandidates(selected);
        } else if(selectedCandidates.length !== 0 && selectedCandidates[0].id === candidate.id) {
            setSelectedCandidates([]);
        } else {
            setSelectedCandidates([candidate]);
        }
    };

    const updateCandidate = async (updatedCandidate) => {
        setIsLoading(true);
        if('resume_file_data' in updatedCandidate) {
            setLoadingBarDuration(10);
        } else {
            setLoadingBarDuration(7);
        }
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedCandidate),
        };
        try {
            const response = await fetch(updateCandidateApi, postParams);
            if (!response.ok) {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error while updating candidate:", errorResponse.message);
                throw new Error(errorResponse.message);
            } else {
                toast.success("Candidate updated successfully");
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
            setLoadingBarDuration(5);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideAddCandidatePopupClick);
        return () => {
            document.removeEventListener('click', handleOutsideAddCandidatePopupClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CandidateContext.Provider value={{
            addCandidatePopupRef,
            candidateList,
            lastEvaluatedKeyCandidates,
            selectedCandidates,
            isAnyCandidateSelected,
            searchBoxValueForCandidate,
            status,
            fetchCandidates,
            isCandidateSelected,
            handleCheckBoxChangeOnCandidateList,
            setSelectedCandidates,
            handleAddCandidateClick,
            handleFindSimilarCandidates,
            handleFindCandidates,
            handleTextSearchCandidates,
            handleCandidateClick,
            setMultipleSelectionAllowedForCandidates,
            updateCandidate,
            setStatus,
            getSubmissionsToJob,
            getSubmissionsOfJob
        }}>
            {children}
        </CandidateContext.Provider>
    );
};