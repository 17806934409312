import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";
import { VIEW } from "../util/Constants";
import { JobContext } from "../context/JobContextProvider";
import { CandidateContext } from "../context/CandidateContextProvider";
/* eslint-disable no-unused-vars */
import log from "../util/Logger";
/* eslint-enable no-unused-vars */

export const useSearchBox = () => {
  const [searchBoxValue, setSearchBoxValue] = useState("");

  return { searchBoxValue, setSearchBoxValue };
}

export default function SearchBox() {
  const { currentView } = useContext(GlobalContext);
  const [searchType, setSearchType] = useState(null);
  const { handleTextSearchJobs, handleFileSearchJobs, searchBoxValueForJob } = useContext(JobContext);
  const { handleTextSearchCandidates, searchBoxValueForCandidate } = useContext(CandidateContext);

  const textAreaRef = useRef(null);

  const handleInput = (e) => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentView === VIEW.ALL_JOBS || currentView === VIEW.MY_JOBS || currentView === VIEW.JOB_SEARCH_RESULTS) {
        handleTextSearchJobs(textAreaRef.current.value);
      } else {
        handleTextSearchCandidates(textAreaRef.current.value);
      }
    }
  };

  const onFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      handleFileSearchJobs(file);
    }
    event.target.value = "";
  };

  useEffect(() => {
    if (currentView !== VIEW.JOB_SEARCH_RESULTS && currentView !== VIEW.CANDIDATE_SEARCH_RESULTS) {
      textAreaRef.current.value = "";
    } else if (currentView === VIEW.JOB_SEARCH_RESULTS && searchBoxValueForJob) {
      textAreaRef.current.value = searchBoxValueForJob;
    } else {
      textAreaRef.current.value = searchBoxValueForCandidate;
    }
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
    if (currentView === VIEW.ALL_JOBS || currentView === VIEW.MY_JOBS || currentView === VIEW.JOB_SEARCH_RESULTS) {
      setSearchType("jobs");
    } else {
      setSearchType("candidates");
    }
  }, [currentView, searchBoxValueForJob, searchBoxValueForCandidate]);

  return (
    <div className="pt-10 pb-2 w-3/4 flex flex-row justify-center items-center mt-25 font-roboto">
      <textarea
        name="prompt-textarea"
        id="prompt-textarea"
        rows="1"
        placeholder={`Search for ${searchType}`}
        className="shadow-md w-3/5 bg-white rounded-xl pl-3 pr-14 py-2 overflow-hidden focus:outline-none focus:ring-0 text-gray-700 resize-none border border-[#24386c] border-opacity-30"
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        ref={textAreaRef}
      ></textarea>
      {searchType === "jobs" ? (
        <>
          <div>
            <button
              onClick={() => handleTextSearchJobs(textAreaRef.current.value)}
              className={`relative -left-16 rounded-md `}>
              <img src="/icons8-search-64.png" alt="Button Icon" className="ml-2 h-5 w-5 mt-1" />
            </button>
          </div>
          <input type="file" id="fileInput" onChange={onFileChange} className="hidden" />
          <label htmlFor="fileInput" className="cursor-pointer relative -left-14">
            <img src="/icons8-resume-96.png" alt="Upload" className="w-6 h-6" />
          </label>
        </>
      ) : (
        <>
          <div>
            <button
              onClick={() => handleTextSearchCandidates(textAreaRef.current.value)}
              className={`relative -left-8 rounded-md `}>
              <img src="/icons8-search-64.png" alt="Button Icon" className="h-5 w-5 mt-1" />
            </button>
          </div>
        </>
      )}
    </div>
  );
}
