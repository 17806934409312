import React, { useContext, useEffect } from 'react'
import { GlobalContext } from '../context/GlobalContextProvider';
import { VIEW } from '../util/Constants';
import { useUtilManager } from '../util/UtilManager';
import { JobContext } from '../context/JobContextProvider';
import { CandidateContext } from '../context/CandidateContextProvider';
import JobList from '../module/JobList';
import CandidateList from '../module/CandidateList';
import log from '../util/Logger';
import { toast } from 'react-toastify';
import GenericButton from '../module/GenericButton';

const jobSubmissionApi = `https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/submit-jobs`;
const candidateSubmissionApi = `https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/submit-candidates`;
const getSubmissionCountOfSourceIdApi = 'https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Stage/get-submissions-count-of-src-id'
const getRecommendationsCountOfSourceIdApi = 'https://p757tbfjc9.execute-api.ap-south-1.amazonaws.com/Prod/get-recommendations-count-of-src-id'
const globalSubmissionLimit = 2;
const globalRecommendationsLimit = 10;
export default function SubmissionView() {
    const { uid, currentView, setIsLoading } = useContext(GlobalContext)
    const { doNothing, isQA, getAiRecommendation } = useUtilManager();

    const {
        isAnyCandidateSelected,
        selectedCandidates,
        candidateList,
        handleCheckBoxChangeOnCandidateList,
        handleCandidateClick,
        handleFindCandidates,
        isCandidateSelected,
        setMultipleSelectionAllowedForCandidates,
        getSubmissionsToJob,
        getSubmissionsOfJob
    } = useContext(CandidateContext);

    const {
        selectedJobs,
        isAnyJobSelected,
        jobList,
        handleCheckBoxChangeOnJobList,
        handleJobClick,
        handleFindJobs,
        isJobSelected,
        setMultipleSelectionAllowedForJobs,
        getSubmissionsToCandidate,
        getSubmissionsOfCandidate
    } = useContext(JobContext);

    const buttonText = () => {
        switch (currentView) {
            case VIEW.SUBMIT_YOUR_CANDIDATES:
            case VIEW.SUBMIT_YOUR_JOBS:
            case VIEW.SUBMIT_TO_CANDIDATES:
            case VIEW.SUBMIT_TO_JOBS: return "Submit";
            case VIEW.SUBMISSIONS_TO_JOB:
            case VIEW.SUBMISSIONS_TO_CANDIDATE: return "Submit Back";
            case VIEW.SUBMISSIONS_OF_JOB:
            case VIEW.SUBMISSIONS_OF_CANDIDATE: return "Submit Again";
            default: return "Submit";
        }
    };

    const topMessage = () => {
        switch (currentView) {
            case VIEW.SUBMIT_YOUR_CANDIDATES: return "Submit your candidates to the selected job"
            case VIEW.SUBMIT_YOUR_JOBS: return "Submit your jobs to the selected candidate"
            case VIEW.SUBMIT_TO_CANDIDATES: return "Submit your job to the top matched candidates"
            case VIEW.SUBMIT_TO_JOBS: return "Submit your candidate to the top matched jobs"
            case VIEW.SUBMISSIONS_TO_JOB: return "Submissions to your job"
            case VIEW.SUBMISSIONS_OF_JOB: return "Submissions of your job"
            case VIEW.SUBMISSIONS_TO_CANDIDATE: return "Submissions to your candidate"
            case VIEW.SUBMISSIONS_OF_CANDIDATE: return "Submissions of your candidate"
            default: return null;
        }
    };

    const getTopListMessage = () => {
        switch (currentView) {
            case VIEW.SUBMIT_YOUR_CANDIDATES: return "Selected Job"
            case VIEW.SUBMIT_YOUR_JOBS: return "Selected Candidate"
            case VIEW.SUBMISSIONS_TO_CANDIDATE:
            case VIEW.SUBMISSIONS_OF_CANDIDATE:
            case VIEW.SUBMIT_TO_JOBS: return "Your Candidate"
            case VIEW.SUBMISSIONS_TO_JOB:
            case VIEW.SUBMISSIONS_OF_JOB:
            case VIEW.SUBMIT_TO_CANDIDATES: return "Your Job"
            default: return null;
        }
    }

    const getBottomListFirstMessage = () => {
        switch (currentView) {
            case VIEW.SUBMIT_YOUR_CANDIDATES: return "Your Candidates"
            case VIEW.SUBMIT_YOUR_JOBS: return "Your Jobs"
            case VIEW.SUBMIT_TO_CANDIDATES: return "Top Matched Candidates"
            case VIEW.SUBMIT_TO_JOBS: return "Top Matched Jobs"
            case VIEW.SUBMISSIONS_TO_JOB: return "Submitted Candidates"
            case VIEW.SUBMISSIONS_OF_JOB: return "Submissions"
            case VIEW.SUBMISSIONS_TO_CANDIDATE: return "Submitted Jobs"
            case VIEW.SUBMISSIONS_OF_CANDIDATE: return "Submissions"
            default: return null;
        }
    }

    const getBottomListSecondMessage = () => {
        switch (currentView) {
            case VIEW.SUBMIT_YOUR_CANDIDATES: return "(Please select one or more candidate for submission and AI recommendation. Click on the AI recommendations to see the reasoning if needed.)"
            case VIEW.SUBMIT_YOUR_JOBS: return "(Please select one or more job for submission and AI recommendation. Click on the AI recommendations to see the reasoning if needed.)"
            case VIEW.SUBMIT_TO_CANDIDATES: return "(Please select one or more candidate for submission and AI recommendation. Click on the AI recommendations to see the reasoning if needed.)"
            case VIEW.SUBMIT_TO_JOBS: return "(Please select one or more job for submission and AI recommendation. Click on the AI recommendations to see the reasoning if needed.)"
            case VIEW.SUBMISSIONS_TO_JOB: return "(Following candidates have been submitted to your job. Click on the AI recommendations to see the reasoning if needed.)"
            case VIEW.SUBMISSIONS_OF_JOB: return "(Your job has been submitted to following candidates. Click on the AI recommendations to see the reasoning if needed.)"
            case VIEW.SUBMISSIONS_OF_CANDIDATE: return "(Your candidate has been submitted to following jobs. Click on the AI recommendations to see the reasoning if needed.)"
            case VIEW.SUBMISSIONS_TO_CANDIDATE: return "(Following jobs have been submitted to your candidate. Click on the AI recommendations to see the reasoning if needed.)"
            default: return null;
        }
    }

    const renderTopList = () => {
        switch (currentView) {
            case VIEW.SUBMISSIONS_TO_JOB:
            case VIEW.SUBMISSIONS_OF_JOB:
            case VIEW.SUBMIT_TO_CANDIDATES:
            case VIEW.SUBMIT_YOUR_CANDIDATES: return (
                <JobList
                    jobList={selectedJobs}
                    tableWidth={"1280px"}
                    isJobSelected={isJobSelected}
                    handleJobClick={handleJobClick}
                    handleCheckBoxChange={doNothing}
                    displayScore = {false}
                    displayRecommendation = {false}
                />
            );
            case VIEW.SUBMISSIONS_OF_CANDIDATE:
            case VIEW.SUBMISSIONS_TO_CANDIDATE:
            case VIEW.SUBMIT_TO_JOBS:
            case VIEW.SUBMIT_YOUR_JOBS: return (
                <CandidateList
                    candidateList={selectedCandidates}
                    isCandidateSelected={isCandidateSelected}
                    isCandidateSearchView={false}
                    handleCandidateClick={handleCandidateClick}
                    handleCheckBoxChange={doNothing}
                    displayScore = {false}
                    displayRecommendation = {false}
                />
            );
            default: return null;
        }
    }

    const renderBottomList = () => {
        switch (currentView) {
            case VIEW.SUBMISSIONS_TO_JOB:
            case VIEW.SUBMISSIONS_OF_JOB:
            case VIEW.SUBMIT_TO_CANDIDATES:
            case VIEW.SUBMIT_YOUR_CANDIDATES: return (
                <CandidateList
                    candidateList={candidateList}
                    isCandidateSelected={isCandidateSelected}
                    isCandidateSearchView={true}
                    handleCandidateClick={handleCandidateClick}
                    handleCheckBoxChange={handleCheckBoxChangeOnCandidateList}
                    displayScore = {true}
                    displayRecommendation = {true}
                />
            );
            case VIEW.SUBMISSIONS_OF_CANDIDATE:
            case VIEW.SUBMISSIONS_TO_CANDIDATE:
            case VIEW.SUBMIT_TO_JOBS:
            case VIEW.SUBMIT_YOUR_JOBS: return (
                <JobList
                    jobList={jobList}
                    tableWidth={"1180px"}
                    isJobSelected={isJobSelected}
                    handleJobClick={handleJobClick}
                    handleCheckBoxChange={handleCheckBoxChangeOnJobList}
                    displayScore = {true}
                    displayRecommendation = {true}
                />
            );
            default: return null;
        }
    }

    const getSubmissionItems = (jobs, candidates, search_type) => {
        let items = [];
        for (let i = 0; i < jobs.length; i++) {
            for (let j = 0; j < candidates.length; j++) {
                let score = search_type === 'jobs' ? jobs[i].score : candidates[j].score;
                items.push({ job_id: jobs[i].id, cand_id: candidates[j].id, match_score: score });
            }
        }
        return items;
    };

    const getSourceIdSubmissionCount = async (srcId) => {
        setIsLoading(true);
        const payload = {
            src_id: srcId
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getSubmissionCountOfSourceIdApi, postParams);
            if (response.ok) {
                const data = await response.json();
                return data.count;
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching user submission count", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
            return 0;
        }
    };

    const getSourceIdRecommendationCount = async (srcId) => {
        setIsLoading(true);
        const payload = {
            src_id: srcId
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getRecommendationsCountOfSourceIdApi, postParams);
            if (response.ok) {
                const data = await response.json();
                return data.count;
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching user submission count", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
            return 0;
        }
    };

    const validateRecommendationsLimit = async (srcId, srcType) => {
        let recommendationsCount = await getSourceIdRecommendationCount(srcId);
        let recommendationsLimit = isQA ? 100 : globalRecommendationsLimit;
        if (recommendationsCount >= recommendationsLimit) {
            setIsLoading(false);
            toast.warn(`This ${srcType} reached the daily limit of ${recommendationsLimit} recommendations. Please try again later!`);
            return false;
        }
        return true;
    };

    const getAiRecommendationClick = async () => {
        if (!isAnyJobSelected) {
            toast.warn("Please select a job to get AI recommendation!");
            return;
        }
        if (!isAnyCandidateSelected) {
            toast.warn("Please select a candidate to get AI recommendation!");
            return;
        }
        switch (currentView) {
            case VIEW.SUBMISSIONS_TO_JOB:
            case VIEW.SUBMISSIONS_OF_JOB:
            case VIEW.SUBMIT_TO_CANDIDATES: {
                if (!await validateRecommendationsLimit(selectedJobs[0].id, 'job')) {
                    return;
                }
                let recommendations = await getAiRecommendation(selectedJobs, selectedCandidates, 'job', 'candidate');
                for (let i = 0; i < recommendations.length; i++) {
                    for (let j = 0; j < selectedCandidates.length; j++) {
                        if (recommendations[i].cand_id === selectedCandidates[j].id) {
                            selectedCandidates[j].recommendation = recommendations[i].recommendation;
                            selectedCandidates[j].reasoning = recommendations[i].reasoning;
                        }
                    }
                }
                break;
            }
            case VIEW.SUBMIT_YOUR_CANDIDATES: {
                let recommendations = await getAiRecommendation(selectedJobs, selectedCandidates, 'candidate', 'job');
                for (let i = 0; i < recommendations.length; i++) {
                    for (let j = 0; j < selectedCandidates.length; j++) {
                        if (recommendations[i].cand_id === selectedCandidates[j].id) {
                            selectedCandidates[j].recommendation = recommendations[i].recommendation;
                            selectedCandidates[j].reasoning = recommendations[i].reasoning;
                        }
                    }
                }
                break;
            }
            case VIEW.SUBMISSIONS_OF_CANDIDATE:
            case VIEW.SUBMISSIONS_TO_CANDIDATE:
            case VIEW.SUBMIT_TO_JOBS : {
                if (!await validateRecommendationsLimit(selectedCandidates[0].id, 'candidate')) {
                    return;
                }
                let recommendations = await getAiRecommendation(selectedJobs, selectedCandidates, 'candidate', 'job');
                for (let i = 0; i < recommendations.length; i++) {
                    for (let j = 0; j < selectedJobs.length; j++) {
                        if (recommendations[i].job_id === selectedJobs[j].id) {
                            selectedJobs[j].recommendation = recommendations[i].recommendation;
                            selectedJobs[j].reasoning = recommendations[i].reasoning;
                        }
                    }
                }
                break;
            }
            case VIEW.SUBMIT_YOUR_JOBS: {
                let recommendations = await getAiRecommendation(selectedJobs, selectedCandidates, 'job', 'candidate');
                for (let i = 0; i < recommendations.length; i++) {
                    for (let j = 0; j < selectedJobs.length; j++) {
                        if (recommendations[i].job_id === selectedJobs[j].id) {
                            selectedJobs[j].recommendation = recommendations[i].recommendation;
                            selectedJobs[j].reasoning = recommendations[i].reasoning;
                        }
                    }
                }
                break;
            }
            default: return null;
        }
    };

    const validateSubmissionLimit = async (jobs, candidates, submission_type) => {
        let srcId = null;
        if (submission_type === 'job_submission') {
            srcId = jobs[0].id;
        } else {
            srcId = candidates[0].id;
        }
        let submissionCount = await getSourceIdSubmissionCount(srcId);
        let submissionLimit = isQA ? 100 : globalSubmissionLimit;
        if (submissionCount >= submissionLimit) {
            setIsLoading(false);
            toast.warn(`This ${submission_type === 'job_submission' ? 'job' : 'candidate'} reached the daily limit of ${submissionLimit} submissions. Keeping submission limit low for couple of weeks to avoid spams. We will increase the limit in few weeks.Please try again tomorrow!`);
            return false;
        }
        return true;
    };

    const handleSubmission = async (jobs, candidates, submission_type, search_type) => {
        if (candidates.length === 0) {
            toast.warn('Please select at least one candidate!');
            return;
        }
        if (jobs.length === 0) {
            toast.warn('Please select at least one job');
            return;
        }
        if (!await validateSubmissionLimit(jobs, candidates, submission_type)) {
            return;
        }
        let apiUrl = submission_type === 'job_submission' ? jobSubmissionApi : candidateSubmissionApi;
        let items = getSubmissionItems(jobs, candidates, search_type);
        const payload = {
            items: items,
        };
        const post_params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        fetch(apiUrl, post_params)
            .then((response) => {
                if (!response.ok) {
                    return response.text().then((errorText) => {
                        throw new Error(errorText);
                    });
                } else {
                    toast.success('Submission sucessfull!');
                    // navigate(-1);
                }
                log.debug(response.text)
            })
            .catch((error) => {
                toast.error(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        switch (currentView) {
            case VIEW.SUBMIT_YOUR_CANDIDATES:
                if (selectedJobs.length > 1) {
                    throw new Error("Multiple jobs cannot be selected for SUBMIT_YOUR_CANDIDATES");
                }
                handleFindCandidates(selectedJobs[0], 'user', uid, VIEW.SUBMIT_YOUR_CANDIDATES);
                setMultipleSelectionAllowedForCandidates(true);
                break;
            case VIEW.SUBMIT_YOUR_JOBS:
                if (selectedCandidates.length > 1) {
                    throw new Error("Multiple candidates cannot be selected for SUBMIT_YOUR_JOBS");
                }
                handleFindJobs(selectedCandidates[0], 'user', uid, VIEW.SUBMIT_YOUR_JOBS);
                setMultipleSelectionAllowedForJobs(true);
                break;
            case VIEW.SUBMIT_TO_CANDIDATES:
                if (selectedJobs.length > 1) {
                    throw new Error("Multiple jobs cannot be selected for SUBMIT_TO_CANDIDATES");
                }
                handleFindCandidates(selectedJobs[0], 'all', uid, VIEW.SUBMIT_TO_CANDIDATES);
                setMultipleSelectionAllowedForCandidates(true);
                break;
            case VIEW.SUBMIT_TO_JOBS:
                if (selectedCandidates.length > 1) {
                    throw new Error("Multiple candidates cannot be selected for SUBMIT_TO_JOBS");
                }
                handleFindJobs(selectedCandidates[0], 'all', uid, VIEW.SUBMIT_TO_JOBS);
                setMultipleSelectionAllowedForJobs(true);
                break;
            case VIEW.SUBMISSIONS_TO_JOB:
                if (selectedJobs.length > 1) {
                    throw new Error("Multiple jobs cannot be selected for SUBMISSIONS_TO_JOB");
                }
                getSubmissionsToJob(selectedJobs[0]);
                setMultipleSelectionAllowedForCandidates(true);
                break;
            case VIEW.SUBMISSIONS_OF_JOB:
                if (selectedJobs.length > 1) {
                    throw new Error("Multiple jobs cannot be selected for SUBMISSIONS_OF_JOB");
                }
                getSubmissionsOfJob(selectedJobs[0]);
                setMultipleSelectionAllowedForCandidates(true);
                break;
            case VIEW.SUBMISSIONS_OF_CANDIDATE:
                if (selectedCandidates.length > 1) {
                    throw new Error("Multiple candidates cannot be selected for SUBMISSIONS_OF_CANDIDATE");
                }
                getSubmissionsOfCandidate(selectedCandidates[0]);
                setMultipleSelectionAllowedForJobs(true);
                break;
            case VIEW.SUBMISSIONS_TO_CANDIDATE:
                if (selectedCandidates.length > 1) {
                    throw new Error("Multiple candidates cannot be selected for SUBMISSIONS_TO_CANDIDATE");
                }
                getSubmissionsToCandidate(selectedCandidates[0]);
                setMultipleSelectionAllowedForJobs(true);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentView]);

    return (
        <>
            <p className='mt-3 font-roboto font-semibold bg-gray-300 px-10 py-1 rounded-sm'>{topMessage()}</p>
            <div className='mr-3'>
                <div className='mt-1 pt-1 flex flex-row justify-start font-roboto'>
                    <p className='text-base font-semibold'>{getTopListMessage()}</p>
                </div>
                {renderTopList()}
                <hr />
            </div>
            <div>
                <div className='flex flex-row justify-between mt-4 font-roboto'>
                    <div className='flex flex-row justify-start'>
                        <p className='text-base font-semibold pt-1'>{getBottomListFirstMessage()}</p>
                        <p className='text-[13px] ml-1 pt-[7px]'>{getBottomListSecondMessage()}</p>
                    </div>
                    <div className='pt-2 mb-1 text-[11px] font-semibold'>
                        <GenericButton enable={isAnyJobSelected && isAnyCandidateSelected} callback={getAiRecommendationClick} text={"Get AI Recommendation"} mr={'mr-4'} ml={'ml-0'} />
                    </div>
                </div>
                <div className='h-[440px] overflow-y-scroll'>
                    {renderBottomList()}
                </div>
                <hr />
            </div>
            <div className='mt-3 mb-2 font-roboto font-semibold'>
                <button onClick={event => {
                    event.stopPropagation();
                    switch (currentView) {
                        case VIEW.SUBMIT_YOUR_CANDIDATES:
                            handleSubmission(selectedJobs, selectedCandidates, 'candidate_submission', 'candidates')
                            break;
                        case VIEW.SUBMIT_TO_CANDIDATES:
                            handleSubmission(selectedJobs, selectedCandidates, 'job_submission', 'candidates')
                            break;
                        case VIEW.SUBMIT_YOUR_JOBS:
                            handleSubmission(selectedJobs, selectedCandidates, 'job_submission', 'jobs')
                            break;
                        case VIEW.SUBMIT_TO_JOBS:
                            handleSubmission(selectedJobs, selectedCandidates, 'candidate_submission', 'jobs')
                            break;
                        case VIEW.SUBMISSIONS_TO_JOB:
                            handleSubmission(selectedJobs, selectedCandidates, 'job_submission', 'candidates')
                            break;
                        case VIEW.SUBMISSIONS_OF_JOB:
                            handleSubmission(selectedJobs, selectedCandidates, 'job_submission', 'candidates')
                            break;
                        case VIEW.SUBMISSIONS_TO_CANDIDATE:
                            handleSubmission(selectedJobs, selectedCandidates, 'candidate_submission', 'jobs')
                            break;
                        case VIEW.SUBMISSIONS_OF_CANDIDATE:
                            handleSubmission(selectedJobs, selectedCandidates, 'candidate_submission', 'jobs')
                            break;
                        default:
                            break;
                    }
                }} className={`border-[#24386c] border bg-[#24386c] py-1 px-2 rounded-sm text-[#e8ddfe] font-semibold text-sm ${(isAnyCandidateSelected && isAnyJobSelected) ? "bg-[#24386c] text-[#e8ddfe] hover:bg-[#dc244c]" : "bg-[#e8ddfe] text-gray-800"}`}>
                    {buttonText()}
                </button>
            </div>
        </>
    )
}
