import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { POP_UP, VIEW } from '../util/Constants';
import { GlobalContext } from './GlobalContextProvider';
import { useNavigate } from 'react-router-dom';
/* eslint-disable no-unused-vars */
import log from "../util/Logger";
import { toast } from 'react-toastify';
/* eslint-enable no-unused-vars */

export const JobContext = createContext();

const getJobsApi = 'https://m9l0gpbw58.execute-api.ap-south-1.amazonaws.com/Prod/get-jobs';
const jobSearchApiUrl = 'https://lsaxfnc3jg.execute-api.ap-south-1.amazonaws.com/Prod/search-jobs';
const updateJobApiUrl = 'https://4a26jc2jg8.execute-api.ap-south-1.amazonaws.com/Prod/update-job';
const getSubmissionsToApi = 'https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/get-submissions-to'
const getSubmissionsOfApi = 'https://zl8byzl7c1.execute-api.ap-south-1.amazonaws.com/Prod/get-submissions-of'

export const JobContextProvider = ({ children }) => {
    const { isLoggedIn, uid, setIsLoading, setLoadingBarDuration, setPopup } = useContext(GlobalContext);
    const [jobList, setJobList] = useState([]);
    const [lastEvaluatedKeyJobs, setLastEvaluatedKeyJobs] = useState(null);
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [multipleSelectionAllowedForJobs, setMultipleSelectionAllowedForJobs] = useState(false);
    const [searchBoxValueForJob, setSearchBoxValueForJob] = useState("");
    const [status, setStatus] = useState('active');
    const addJobPopupRef = useRef(null);
    const navigate = useNavigate();

    const fetchJobs = async (key = null, context = null) => {
        if (context === VIEW.MY_JOBS && !isLoggedIn) {
            return;
        }
        setJobList([]);
        setSelectedJobs([]);
        setIsLoading(true);
        const payload = {
            last_evaluated_key: key,
            context: context,
            uid: uid,
            status: status
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getJobsApi, postParams);
            if (response.ok) {
                const data = await response.json();
                setJobList(data.Items);
                const returnedLastEvaluatedKey = data.LastEvaluatedKey;
                if (returnedLastEvaluatedKey) {
                    const encoded = btoa(
                        unescape(encodeURIComponent(JSON.stringify(returnedLastEvaluatedKey)))
                    );
                    setLastEvaluatedKeyJobs(encoded);
                } else {
                    setLastEvaluatedKeyJobs('end');
                }
            } else {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getSubmissionsToCandidate = async (candidate) => {
        setJobList([]);
        setSelectedJobs([]);
        setIsLoading(true);
        const payload = {
            dst_id: candidate.id,
            dst_type: 'candidate',
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getSubmissionsToApi, postParams);
            if (response.ok) {
                const data = await response.json();
                if (data.length !== 0) {
                    setJobList(data);
                }
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching jobs:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getSubmissionsOfCandidate = async (candidate) => {
        setJobList([]);
        setSelectedJobs([]);
        setIsLoading(true);
        const payload = {
            src_id: candidate.id,
            src_type: 'candidate',
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(getSubmissionsOfApi, postParams);
            if (response.ok) {
                const data = await response.json();
                if (data.length !== 0) {
                    setJobList(data);
                }
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error fetching candidates:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const isAnyJobSelected = useMemo(() => {
        return selectedJobs.length > 0;
    }, [selectedJobs]);

    const sortBasedOnDateAndScore = (results) => {
        return results.sort((a, b) => {
            const dateA = new Date(a.ts * 1000);
            const dateB = new Date(b.ts * 1000);
    
            // Compare only the year, month, and day
            const yearDiff = dateB.getFullYear() - dateA.getFullYear();
            const monthDiff = dateB.getMonth() - dateA.getMonth();
            const dayDiff = dateB.getDate() - dateA.getDate();
    
            if (yearDiff !== 0) return yearDiff;
            if (monthDiff !== 0) return monthDiff;
            if (dayDiff !== 0) return dayDiff;
    
            // If dates are the same, sort by score
            const scoreA = a.score;
            const scoreB = b.score;
            if (scoreA < scoreB) return 1;
            if (scoreA > scoreB) return -1;
            return 0;
        });
    };
    

    const searchJobs = async (searchType, searchValue, scope='all', user=null, useCase='search') => {
        setIsLoading(true);
        setJobList([]);
        setSelectedJobs([]);
        const payload = {
            search_type: searchType,
            search_value: searchValue,
            scope: scope,
            uid: user,
            use_case: useCase
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(jobSearchApiUrl, postParams);
            if (response.ok) {
                const data = await response.json();
                sortBasedOnDateAndScore(data);
                setJobList(data);
                window.scrollTo(0, 0);
            } else {
                log.debug('Response not ok, processing error...');
                const errorResponse = await response.json();
                log.debug("Error searching jobs:", errorResponse.message);
                throw new Error(errorResponse.message);
            }
        } catch (error) {
            toast.error(error.message);
            navigate(`/${VIEW.ALL_JOBS}`);
        } finally {
            setIsLoading(false);
        }
    }

    const handleFindSimilarJobs = (job, scope, user) => {
        setSearchBoxValueForJob(job.title + " at " + job.loc);
        searchJobs('job_id', job.id, scope, user);
    };

    const handleFindJobs = (cand, scope, user, useCase='search') => {
        if (cand === null || cand === undefined) {
            navigate(-1);
            return;
        }
        let value = cand.name;
        if (cand.loc !== 'NA') { value += ", Location: " + cand.loc; }
        if (cand.exp) { value += ", Exp: " + cand.exp + " Years"; }
        if (cand.top_skills) { value += ", Top Skills: " + cand.top_skills; }
        setSearchBoxValueForJob(value);
        searchJobs('cand_id', cand.id, scope, user, useCase);
    };

    const handleTextSearchJobs = (textAreaValue, scope, user) => {
        setSearchBoxValueForJob(textAreaValue);
        navigate(`/${VIEW.JOB_SEARCH_RESULTS}`)
        searchJobs('text', textAreaValue, scope, user);
    };

    const handleFileSearchJobs = (file, scope='all') => {
        navigate(`/${VIEW.JOB_SEARCH_RESULTS}/${file.name}`)
        setSearchBoxValueForJob(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64Content = reader.result.split(',')[1];
            const search_value = {
                file_name: file.name,
                file_content: base64Content,
            }
            searchJobs('file', search_value, scope);
        }
    };

    /**
     * Following two methods are used for submitting jobs
     */

    const isJobSelected = (job) => {
        return selectedJobs.some((c) => c.id === job.id);
    }

    const handleCheckBoxChangeOnJobList = (job) => {
        if(multipleSelectionAllowedForJobs) {
            let selected = [...selectedJobs];
            if (selected.some((c) => c.id === job.id)) {
                selected = selected.filter((c) => c.id !== job.id);
            } else {
                if (selected.length >= 5) {
                    toast.warn("You can only select up to 5 jobs !!!");
                    return;
                }
                selected.push(job);
            }
            setSelectedJobs(selected);
        } else if(selectedJobs.length !== 0 && selectedJobs[0].id === job.id) {
            setSelectedJobs([]);
        } else {
            setSelectedJobs([job]);
        }
    };

    const handleJobClick = (job) => {
        // window.scrollTo(0, 0);
        // setSelectedJobs([job]);
        window.open(`/${VIEW.JOB_DETAILS}/${job.id}`, '_blank');
    };

    const handleOutsideAddJobPopupClick = (event) => {
        if (addJobPopupRef.current && !addJobPopupRef.current.contains(event.target)) {
            setPopup(POP_UP.NONE);
        }
    };

    const handleAddJobClick = (event) => {
        if(!isLoggedIn) {
            toast.warn("Please login to add job");
            navigate(`/${VIEW.LOGIN}`);
            return;
        }
        if(selectedJobs.length !== 0) {
            toast.warn("Please deselect the job before adding a new one");
            return;
        }
        setPopup(POP_UP.ADD_JOB_POPUP);
    };

    const updateJob = async (updatedJob) => {
        setLoadingBarDuration(7);
        setIsLoading(true);
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedJob),
        };
        try {
            const response = await fetch(updateJobApiUrl, postParams);
            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message);
            } else {
                toast.success("Job updated successfully!");
            }
        } catch (error) {
            log.error(error.message);
        } finally {
            setIsLoading(false);
            setLoadingBarDuration(5);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideAddJobPopupClick);
        return () => {
            document.removeEventListener('click', handleOutsideAddJobPopupClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <JobContext.Provider value={{
            jobList,
            selectedJobs,
            isAnyJobSelected,
            searchBoxValueForJob,
            lastEvaluatedKeyJobs,
            status,
            addJobPopupRef,
            handleAddJobClick,
            updateJob,
            setStatus,
            fetchJobs,
            setSelectedJobs,
            isJobSelected,
            handleCheckBoxChangeOnJobList,
            handleFindSimilarJobs,
            handleFindJobs,
            handleTextSearchJobs,
            handleFileSearchJobs,
            handleJobClick,
            setMultipleSelectionAllowedForJobs,
            getSubmissionsToCandidate,
            getSubmissionsOfCandidate
        }}>
            {children}
        </JobContext.Provider>
    );
};
