import React, { useContext, useEffect, useMemo } from 'react'
import SearchBox from '../../module/SearchBox';
import GenericButton from '../../module/GenericButton';
import CandidateList from '../../module/CandidateList';
import { CandidateContext } from '../../context/CandidateContextProvider';
import { GlobalContext } from '../../context/GlobalContextProvider';
import { useUtilManager } from '../../util/UtilManager';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { POP_UP, VIEW } from '../../util/Constants';
import { toast } from 'react-toastify';

export default function CandidatesView() {
    const { isLoggedIn, currentView, candidateViewRefresher, setPopup, setCandidateViewRefresher } = useContext(GlobalContext);
    const location = useLocation();
    const currentPath = location.pathname;
    let { id } = useParams();
    const navigate = useNavigate();

    const {
        isQA,
        handleSubmitToJobsClick,
        handleSubmitYourJobsClick,
        handleSimilarCandidatesClick,
        handleFindJobsClick,
        handleSubmissionToCandidateClick,
        handleSubmissionOfCandidateClick
    } = useUtilManager();

    const {
        status,
        setStatus,
        candidateList,
        selectedCandidates,
        lastEvaluatedKeyCandidates,
        isAnyCandidateSelected,
        handleCheckBoxChangeOnCandidateList,
        handleCandidateClick,
        handleAddCandidateClick,
        isCandidateSelected,
        fetchCandidates,
        setMultipleSelectionAllowedForCandidates,
        updateCandidate
    } = useContext(CandidateContext);

    const isActive = useMemo(() => {
        return status === 'active';
    }, [status]);

    const enablePrev = useMemo(() => {
        return id !== null && id !== undefined;
    }, [id]);

    const isCandidateSearchView = useMemo(() => {
        return currentView === VIEW.CANDIDATE_SEARCH_RESULTS;
    }, [currentView]);

    const isMyCandidatesView = useMemo(() => {
        return currentView === VIEW.MY_CANDIDATES;
    }, [currentView]);

    const isAllCandidatesView = useMemo(() => {
        return currentView === VIEW.ALL_CANDIDATES;
    }, [currentView]);

    const handleUpdateCandidate = () => {
        if (selectedCandidates.length === 0) {
            toast.warn("Please select a candidate to update!");
            return;
        }
        setPopup(POP_UP.UPDATE_CANDIDATE_POPUP);
    }

    const handleRenewDate = async () => {
        if (selectedCandidates.length === 0) {
            toast.warn("Please select a candidate to refresh!");
            return;
        }
        const updatedCandidate = {
            id: selectedCandidates[0].id
        };
        await updateCandidate(updatedCandidate);
        setCandidateViewRefresher((candidateViewRefresher + 1) % 2);
    }

    const handleDeactivateCandidate = async () => {
        if (selectedCandidates.length === 0) {
            toast.warn("Please select a candidate to deactivate!");
            return;
        }
        const updatedCandidate = {
            id: selectedCandidates[0].id,
            status: 'inactive',
            uid_status: selectedCandidates[0].uid + '#inactive',
        };
        await updateCandidate(updatedCandidate);
        setCandidateViewRefresher((candidateViewRefresher + 1) % 2);
    }

    const handleActivateCandidate = async () => {
        if (selectedCandidates.length === 0) {
            toast.warn("Please select a candidate to activate!");
            return;
        }
        const updatedCandidate = {
            id: selectedCandidates[0].id,
            status: 'active',
            uid_status: selectedCandidates[0].uid + '#active',
        };
        await updateCandidate(updatedCandidate);
        setCandidateViewRefresher((candidateViewRefresher + 1) % 2);
    }

    const handleDeleteCandidate = async () => {
        if (selectedCandidates.length === 0) {
            toast.warn("Please select a candidate to delete!");
            return;
        }
        const updatedCandidate = {
            id: selectedCandidates[0].id,
            status: 'deleted',
            uid_status: selectedCandidates[0].uid + '#deleted'
        };
        await updateCandidate(updatedCandidate);
        setCandidateViewRefresher((candidateViewRefresher + 1) % 2);
    }

    const handleStatusCheckBoxChange = (event) => {
        setStatus(event.target.checked ? 'inactive' : 'active');
        navigate(`/${currentView}`);
    };

    const handleRefresh = () => {
        if (enablePrev) {
            navigate(`/${currentView}`);
        } else {
            fetchCandidates(null, currentView);
        }
    };

    const handleNext = () => {
        navigate(`/${currentView}/${lastEvaluatedKeyCandidates}`);
    };

    const handlePrev = () => {
        if (enablePrev) {
            navigate(-1);
        }
    };

    useEffect(() => {
        const currentView = currentPath.split('/')[1];
        if (currentView === VIEW.ALL_CANDIDATES || currentView === VIEW.MY_CANDIDATES) {
            fetchCandidates(id, currentView);
            setMultipleSelectionAllowedForCandidates(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidateViewRefresher, currentPath, status]);

    return (
        <>
            <SearchBox />
            <div>
                <div className='mt-4 text-[#e8ddfe] mb-1 flex flex-row justify-between text-right font-roboto text-[11px] font-semibold'>
                    <div className='flex flex-row justify-start'>
                        {isCandidateSearchView &&
                            <GenericButton enable={isAnyCandidateSelected} callback={handleSubmitYourJobsClick} text={"Submit Your Jobs"} mr={'mr-2'} ml={'ml-0'} />
                        }
                        {isMyCandidatesView &&
                            <>
                                <GenericButton enable={isLoggedIn && !isAnyCandidateSelected} callback={handleAddCandidateClick} text={"Add New Candidate"} mr={'mr-8'} ml={'ml-0'} />
                                <GenericButton enable={isAnyCandidateSelected && isActive} callback={handleSubmitToJobsClick} text={"Submit to Jobs"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyCandidateSelected && isActive} callback={handleSubmissionToCandidateClick} text={"Submissions To this Candidate"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyCandidateSelected && isActive} callback={handleSubmissionOfCandidateClick} text={"My Submissions Of this Candidate"} mr={'mr-8'} ml={'ml-0'} />
                                <GenericButton enable={isAnyCandidateSelected} callback={handleRenewDate} text={"Renew Date"} mr={'mr-2'} ml={'ml-0'} />
                                {isActive && <GenericButton enable={isAnyCandidateSelected} callback={handleDeactivateCandidate} text={"Deactivate"} mr={'mr-2'} ml={'ml-0'} />}
                                {!isActive && <GenericButton enable={isAnyCandidateSelected} callback={handleActivateCandidate} text={"Activate"} mr={'mr-2'} ml={'ml-0'} />}
                                {/* <GenericButton enable={isAnyCandidateSelected} callback={handleDeleteCandidate} text={"Delete"} mr={'mr-2'} ml={'ml-0'} /> */}
                                <GenericButton enable={isAnyCandidateSelected} callback={handleUpdateCandidate} text={"Update"} mr={'mr-2'} ml={'ml-0'} />
                            </>
                        }
                        {isAllCandidatesView && (
                            <>
                                <GenericButton enable={isLoggedIn && !isAnyCandidateSelected} callback={handleAddCandidateClick} text={"Add New Candidate"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyCandidateSelected} callback={handleSubmitYourJobsClick} text={"Submit Your Jobs"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyCandidateSelected} callback={handleSimilarCandidatesClick} text={"Find Similar Candidates"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyCandidateSelected} callback={handleFindJobsClick} text={"Find Jobs"} mr={'mr-2'} ml={'ml-0'} />
                                {isQA && <GenericButton enable={isAnyCandidateSelected} callback={handleDeleteCandidate} text={"Delete"} mr={'mr-0'} ml={'ml-0'} />}
                            </>
                        )}
                    </div>
                    {!isCandidateSearchView && (<div className="flex flex-row justify-end">
                        {isMyCandidatesView && (<label className="inline-flex items-center mr-2">
                            <input
                                type="checkbox"
                                checked={!isActive}
                                className="form-checkbox h-4 w-4"
                                onChange={handleStatusCheckBoxChange}
                            />
                            <span className="ml-2 text-gray-800 text-[12px]">Deactivated Candidates</span>
                        </label>)}
                        <GenericButton enable={true} callback={handleRefresh} text={"Refresh"} mr={'mr-2'} ml={'ml-0'} />
                        <GenericButton enable={enablePrev} callback={handlePrev} text={"Prev"} mr={'mr-2'} ml={'ml-0'} />
                        <GenericButton enable={true} callback={handleNext} text={"Next"} mr={'mr-0'} ml={'ml-0'} />
                    </div>)}
                </div>
                <CandidateList
                    candidateList={candidateList}
                    isCandidateSelected={isCandidateSelected}
                    isCandidateSearchView={isCandidateSearchView}
                    handleCandidateClick={handleCandidateClick}
                    handleCheckBoxChange={handleCheckBoxChangeOnCandidateList}
                    emptyListMessage={isMyCandidatesView && isActive ? "No candidates found. Add candidates to get started!!!"  : "No candidates found!!!"}
                    displayScore = {isCandidateSearchView}
                    displayRecommendation = {false}
                />
            </div>
        </>
    )
}
